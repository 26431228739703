<template>
  <table-view ref="tableView"
              url="/user/listForPage"
              :filterable="false"
              :filter-form="dataForm"
              :downloadable="isAuthed('sys_user_download')"
              download-url="/user/download/template"
              download-file-name="用户信息模板"
              :uploadable="isAuthed('sys_user_import')"
              upload-title="批量上传用户信息"
              upload-url="/user/batch"
              :upload-columns="uploadColumns"
              :batch-removable="isAuthed('sys_user_delete')"
              delete-url="/user/deleteById"
              :exportable="isAuthed('sys_user_export')"
              export-url="/user/download"
              export-file-name="用户信息表"
              :recovered="isAuthed('sys_user_paused')"
              recovered-url="/user/recover"
              :paused="isAuthed('sys_user_paused')"
              paused-url="/user/pause"
              :addable="isAuthed('sys_user_add')"
              :editable="isAuthed('sys_user_update')"
              :headers="headers"
              show-index
              selectable
              single>
    <el-form slot="search"
             @keyup.enter.native="query">
      <el-select v-model="dataForm.status"
                 clearable
                 filterable
                 placeholder="用户状态">
        <el-option v-for="item in statusList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <el-select v-model="dataForm.departmentId"
                 clearable
                 filterable
                 placeholder="所属基地">
        <el-option v-for="item in baseList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <el-input clearable
                v-model="dataForm.userName"
                placeholder="用户名/真实姓名"></el-input>
      <el-input clearable
                v-model="dataForm.phone"
                placeholder="手机号码"></el-input>
      <el-input clearable
                v-model="dataForm.roleName"
                placeholder="角色名称"></el-input>
      <el-button @click="query"
                 type="primary">查询</el-button>
    </el-form>
  </table-view>

</template>

<script>
import TableView from '@/components/templates/table-view'
import { userTypes } from '@/utils/constants'

export default {
  name: 'person-person',

  components: { TableView },

  data() {
    return {
      headers: [
        { id: 1, prop: 'userName', label: '用户名', width: 150 },
        { id: 2, prop: 'nickName', label: '真实姓名', width: 120 },
        {
          id: 3,
          prop: 'status',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return <el-tag type="success">正常</el-tag>
              case 6:
                return <el-tag type="danger">停用</el-tag>
              default:
                return ''
            }
          },
        },
        { id: 4, prop: 'sex', label: '性别', width: 80 },
        { id: 5, prop: 'phone', label: '手机号码', width: 100 },
        {
          id: 6,
          prop: 'userType',
          label: '员工类型',
          width: 150,
          formatter: (row, column, cellValue) => {
            const type = userTypes.find((t) => t.id === parseInt(cellValue))
            return type ? type.name : ''
          },
        },
        { id: 7, prop: 'companyName', label: '所属公司', width: 100 },
        { id: 8, prop: 'secondDepartmentName', label: '部门/基地', width: 100 },
        { id: 9, prop: 'departmentName', label: '部门/站点', width: 100 },
        { id: 10, prop: 'birthDate', label: '出生日期', width: 100 },
        {
          id: 11,
          prop: 'contractType',
          label: '合同类型',
          width: 100,
          formatter: (row, column, cellValue) => {
            // '勘察状态：1未勘察 2已勘察
            switch (cellValue) {
              case 1:
                return '无限期合同'
              case 2:
                return '固定合同'
              default:
                return ''
            }
          },
        },
        { id: 12, prop: 'contractTime', label: '合同截止日期', width: 100 },
        { id: 13, prop: 'age', label: '年龄', width: 100 },
        { id: 14, prop: 'roleNames', label: '角色', minWidth: 150 },
      ],
      uploadColumns: [
        { id: 1, prop: 'userName', label: '用户名', width: 150 },
        { id: 2, prop: 'nickName', label: '真实姓名', width: 120 },
        { id: 3, prop: 'sex', label: '性别', width: 80 },
        { id: 4, prop: 'nation', label: '民族', width: 80 },
        { id: 5, prop: 'idCare', label: '身份证号码', width: 150 },
        { id: 6, prop: 'birthDate', label: '出生日期', width: 100 },
        { id: 7, prop: 'contractType', label: '合同类型', width: 100 },
        { id: 8, prop: 'contractTime', label: '合同截止日期', width: 100 },
        { id: 9, prop: 'userType', label: '员工类型', width: 100 },
        { id: 10, prop: 'company', label: '所属公司', width: 100 },
        { id: 11, prop: 'phone', label: '联系电话', width: 100 },
        { id: 12, prop: 'userAddress', label: '联系地址', minWidth: 150 },
      ],
      dataForm: {
        userName: '',
        phone: '',
        roleName: '',
        departmentId: '',
        status: 1,
      },
      baseList: [],
      statusList: [
        { id: 1, name: '正常' },
        { id: 6, name: '停用' },
      ],
    }
  },

  methods: {
    query() {
      this.$refs.tableView.queryData()
    },
  },

  watch: {
    dataForm: {
      handler(v) {
        sessionStorage.setItem('person-person-dataForm', JSON.stringify(v))
      },
      deep: true,
    },
  },
  async created() {
    let _dataForm = sessionStorage.getItem('person-person-dataForm')
    if (_dataForm) {
      this.dataForm = Object.assign(this.dataForm, JSON.parse(_dataForm))
    }
    const data = await this.$http({
      url: this.$http.adornUrl('/user/base/list'),
      method: 'post',
    })
    this.baseList = data.datas
  },
}
</script>
